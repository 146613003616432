import React, { useContext, useEffect, useState } from "react";
import "./Faqs.css";
import { HomeContext } from "../../App";
import { Accordion, Modal } from "react-bootstrap";
import API from "../../service/Api";
import Allpagemodal from "../../Components/modal/allpagemodal";

const Faq = () => {
  const data = useContext(HomeContext);
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const faqSectionContent = data.customization.faq.content;
  const faqSectionSty = data.customization.faq.style;

  const view_txt = data.customization.faq.content.CTAbuttonText;
  const arrow_black = data.customization.faq.content.arrow_black;

  const activeQus = {
    color: faqSectionSty.themeColor2,
  };

  const borderSty = {
    borderBottom: "2px solid",
    borderColor: isHover
      ? faqSectionSty.themeColor2
      : faqSectionSty.paraTextColor,
  };

  const handleMouseEnter = (index) => {
    setIsHover(index + 1);
  };

  const handleMouseLeave = (index) => {
    if (index !== "notActive") {
      setIsHover(0);
    }
  };

  const themeColor = {
    color: faqSectionSty.themeColor2 ? faqSectionSty.themeColor2 : "",
  };

  const faqBackground = {
    backgroundColor: faqSectionSty.backgroundColor
      ? faqSectionSty.backgroundColor
      : "",
  };

  const sectionTitle = {
    color: faqSectionSty.titleColor ? faqSectionSty.titleColor : "",
  };

  const quesText = {
    backgroundColor: faqSectionSty.quesbarbackground
      ? faqSectionSty.quesbarbackground
      : "",
    color: faqSectionSty.quesTextColor ? faqSectionSty.quesTextColor : "",
  };
  const activeTextQus = {
    color: faqSectionSty.quesTextColor ? faqSectionSty.quesTextColor : "",
  };
  const answerbarStyle = {
    backgroundColor: faqSectionSty.answerbarbackground
      ? faqSectionSty.answerbarbackground
      : "",
    color: faqSectionSty.paraTextColor ? faqSectionSty.paraTextColor : "",
  };

  const handelActive = (index) => {
    setIsActive(index + 1);
  };

  const initModelData = { name: "", title: "", children: "" };

  const [flag, setFlag] = useState(false);
  const [model, setModel] = useState(initModelData);
  const [apiModel, setApiModel] = useState([]);

  console.log("MODAL DATAXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXxx", model);

  useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        // setWebname(data);
        handleApiCall(data.WEBSITE_NAME, data.WEBSITE_ID);
      });
  }, []);

  const handleApiCall = async (web, websiteId) => {
    try {
      const article = { websiteName: web, webSiteId: websiteId };

      let res = await API.post("Content/getWebsiteContent", article);

      setApiModel(res.data[0]);
    } catch (error) {
      console.error("whySectionerror", error.message);
    }
  };

  const setShow = (arg) => {
    const x = JSON.parse(apiModel[arg] ? apiModel[arg] : {});

    if (x.content.faq) {
      setModel({
        name: "",
        title: x.heading,
        children: JSON.stringify(x.content),
      });
    } else {
      setModel({ name: "", title: x.heading, children: x.content.text });
    }

    setFlag(true);
  };

  return (
    <>
      <div>
        {/* <!-- 7thBlock --> */}

        <div className="container-fluid faq_block" style={faqBackground}>
          <div className="container">
            <div className="innercontainer">
              <h1 className="faq_headding" style={sectionTitle}>
                {faqSectionContent.titleText}{" "}
              </h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="faq_inner">
                  {/* <Accordion defaultActiveKey={defaultActiveKey}> */}
                  <Accordion>
                    {faqSectionContent.faq.map((item, index) => (
                      <Accordion.Item
                        key={item.id}
                        eventKey={item.id}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={
                          isActive === index + 1
                            ? () => handleMouseLeave("notActive")
                            : () => handleMouseLeave(index)
                        }
                        style={
                          isHover === index + 1 || isActive === index + 1
                            ? borderSty
                            : {}
                        }
                        onClick={() => handelActive(index)}
                      >
                        <Accordion.Header
                          className="qus_txt"
                          style={activeTextQus}
                        >
                          <p
                            className="faq_qus"
                            style={
                              isActive === index + 1
                                ? activeQus
                                : {} && activeTextQus
                            }
                          >
                            {" "}
                            {item.qus}
                          </p>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p
                            style={answerbarStyle}
                            className="ans_txt"
                            dangerouslySetInnerHTML={{ __html: item.ans }}
                          ></p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
                <div>
                  <button className="view_btn" onClick={() => setShow("faq")}>
                    {view_txt}{" "}
                    <img
                      src={`static/assets/images/${arrow_black}`}
                      alt="arrow_black"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Allpagemodal
        flag={flag}
        setFlagClose={() => setFlag(false)}
        name={model["name"]}
        bodyTitle={model["title"]}
        modelData={model}
      >
        <>{model["children"]}</>
      </Allpagemodal>
    </>
  );
};

export default Faq;
