import React, { useContext, useState } from "react";
import "./HowWorks.css";
import { HomeContext } from "../../App";
import { Container } from "react-bootstrap";
const HowWorks = ({ onButtonClick }) => {
  const data = useContext(HomeContext);
  const [isHover, setIsHover] = useState(false);

  const howItSectionContent = data.customization.howitworks.content;
  const howitworksSty = data.customization.howitworks.style;
  const howItSectionImag = data.section2_img;

  var splitag = howItSectionImag.split(",");
  var howitImg1 = splitag[0];
  var howitImg2 = splitag[1];
  var howitImg3 = splitag[2];
  console.log("howitUS IMAGE==>>>>>>>>>>", howitImg1);

  const bgColor = {
    backgroundColor: howitworksSty.backgroundColor,
  };

  const ctaStyle = {
    backgroundColor: isHover ? howitworksSty.ctaHoverBg : howitworksSty.ctaBg,
    color: isHover
      ? howitworksSty.ctaHoverTextColor
      : howitworksSty.ctaTextColor,
    border: "1px solid",
    borderTopColor: isHover ? howitworksSty.ctaHoverBg : howitworksSty.ctaBg,
    borderLeftColor: isHover ? howitworksSty.ctaHoverBg : howitworksSty.ctaBg,
    borderRightColor: isHover ? howitworksSty.ctaHoverBg : howitworksSty.ctaBg,
    borderBottomColor: isHover ? howitworksSty.ctaHoverBg : howitworksSty.ctaBg,
  };

  const handleClick = () => {
    onButtonClick();
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <Container fluid className="why_blockbg" style={bgColor}>
      <div className="container">
        <div className="">
          <h1 className="whyus_hadding">
            {howItSectionContent.titleText ? howItSectionContent.titleText : ""}{" "}
          </h1>
          <div className="row row_sty how_works">
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="box_main mob_sty">
                <div className="box_img">
                  <img
                    src={`static/assets/images/${howitImg1}`}
                    alt="howitimg"
                  />
                </div>
                <h1 className="box_headding">
                  {" "}
                  {howItSectionContent.step1headtext
                    ? howItSectionContent.step1headtext
                    : ""}
                </h1>
                <p className="box_par">
                  {howItSectionContent.step1paratext
                    ? howItSectionContent.step1paratext
                    : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="box_main mob_sty">
                <div className="box_img">
                  <img
                    src={`static/assets/images/${howitImg2}`}
                    alt="howitimg"
                  />
                </div>
                <h1 className="box_headding">
                  {howItSectionContent.step2headtext
                    ? howItSectionContent.step2headtext
                    : ""}
                </h1>
                <p className="box_par">
                  {howItSectionContent.step2paratext
                    ? howItSectionContent.step2paratext
                    : ""}
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="box_main mob_sty">
                <div className="box_img">
                  <img
                    src={`static/assets/images/${howitImg3}`}
                    alt="howitimg"
                  />
                </div>
                <h1 className="box_headding">
                  {howItSectionContent.step3headtext
                    ? howItSectionContent.step3headtext
                    : ""}
                </h1>
                <p className="box_par">
                  {howItSectionContent.step3paratext
                    ? howItSectionContent.step3paratext
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="req_main">
            <button
              className="req_btn"
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={ctaStyle}
            >
              {howItSectionContent.CTAbuttonText}
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HowWorks;
